<template>
  <div>
    <!-- 票据基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 质押信息 -->
    <el-card>
      <el-form :model="pledgeInfo" 
               :rules="pledgeOneRules" 
               ref="pledgeOneRef" 
               label-width="140px"
               class="pledge-one-form">
        <el-form-item label="质权人类型：" prop="creditorType">
          <el-radio-group v-model="pledgeInfo.creditorType" @change="creditorTypeChange">
            <el-radio label="ENT">企业：</el-radio>
            <el-radio label="ORG">银行或财司：</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="质权人：" prop="creditor">
          <el-input v-model.trim="pledgeInfo.creditor"  @input="creditorInput"></el-input>
        </el-form-item>
        <template v-if="isPledgeAccount">
          <el-form-item label="质权人账号：" prop="acctNo">
            <el-select v-model="pledgeInfo.acctNo" placeholder="请选择" @change="acctNoChange">
              <el-option v-for="(item,index) in accountList" 
                         key="index"
                         :label="item" 
                         :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户行：" prop="acctBankName">
            <span>{{pledgeInfo.acctBankName}}</span>
          </el-form-item>
        </template>
        <el-form-item label="开户行行号：" prop="acctBankNo">
          <span>{{pledgeInfo.acctBankNo}}</span>
        </el-form-item>
        <el-form-item label="质押金额（元）：" prop="pledgeAmt">
          <el-input v-model="pledgeInfo.pledgeAmt"></el-input>
        </el-form-item>
        <el-form-item label="申请日期：" prop="applyDate">
          <span>{{pledgeInfo.applyDate}}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="pledgeInfo.remark"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSubmit">下一步</el-button>
		</div>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  //按需引入lodash防抖函数
  import debounce from 'lodash/debounce'
  export default {
    name: 'PledgeApplyOne',
    components: {
      WorkbenchPublicTitle
    },
    mounted(){
      // 票据id	--- 列表传过来的
      this.ticketId = this.$route.query.ticketId
      // 获取质押信息
      this.getInfo()
    },
    data(){
      return {
        ticketId: '', // 票据id	--- 列表传过来的
        accountList: [], // 质权人账号列表
        superInfoList: [], // 公共组件 - 票面预览/下载
        pledgeInfo:{// 质押信息表单
          creditorType: '', //质权人类型：企业：ENT、银行或财司：ORG
          creditor: '', // 质权人
          acctNo: '', //质权人账号
          acctBankName: '', // 开户行
          acctBankNo: '', // 开户行行号 
          pledgeAmt: '', // 质押金额（元）
          applyDate: '', // 申请日期
          remark: '' // 备注
        },
        // 质押信息表单验证规则
        pledgeOneRules: {
          creditorType: [
            { required: true, message: '请选择质权人类型', trigger: 'change' }
          ],
          creditor: [
            { required: true, message: '请输入质权人', trigger: 'blur' },
          ],
          acctNo: [
            { required: true, message: '请选择质权人账号', trigger: 'change' }
          ],
          acctBankName: [
            { required: true, message: '开户行不能为空', trigger: 'blur' },
          ],
          pledgeAmt: [
            { required: true, message: '请输入质押金额', trigger: 'blur' },
          ]
        }
      }
    },
    watch: {
      // 侦听质押信息 -- 获取质权人类型
      pledgeInfo: {
        handler(value){
          if(value.creditorType == 'ORG'){
            this.pledgeInfo.acctNo = ''
            this.pledgeInfo.acctBankName = ''
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      // 计算质权人类型 ---  企业/银行或财司
      isPledgeAccount(){
        return this.pledgeInfo.creditorType == 'ENT' ? true : false  //企业：ENT、银行或财司：ORG
      }
    },
    methods: {
      // 获取质押信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/zy/mange/apply/info/${this.ticketId}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.superInfoList = res.data.ticketBaseInfoList
        this.pledgeInfo = res.data.pledgeInfo
      },
      // 监听质权人的输入
      creditorInput: debounce(function(value){
        this.accountList = 0
        this.pledgeInfo.acctNo = ''
        this.pledgeInfo.acctBankName = ''
        this.pledgeInfo.acctBankNo = ''
        if(value){
          if(this.pledgeInfo.creditorType == 'ENT'){
            this.getAcctNo()
          }else{
            this.getOrgAcctBankNo()
          }
        }else{
          return this.$message.error('质权人不能为空')
        }
      },500),
      // 获取质权人账号
      async getAcctNo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/accounts/${this.pledgeInfo.creditor}`)
        if(this.pledgeInfo.creditorType == 'ENT'){
          this.accountList = res.data.accountList
        }else if(this.pledgeInfo.creditorType == 'ORG'){
          this.pledgeInfo.acctBankNo = res.data.accountList[0]
        }
      },
      // 监听选择质权人账号 -- 获取开户行和开户行行号
      async acctNoChange(value){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/account/info/${value}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.pledgeInfo.acctBankName = res.data.acctBankName
        this.pledgeInfo.acctBankNo = res.data.acctBankNo
      },
      // 质权人类型为银行或财司（ORG）获取开户行行号
      async getOrgAcctBankNo(){
        let params = {
          companyName: this.pledgeInfo.creditor,
          billType: 'AC01'
        }
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/ticket/getAcceptorSearch', this.$qs.stringify(params))
        if(res.code !== 200) return this.$message.error(res.msg)
        this.pledgeInfo.acctBankNo = res.orgBankCode
      },
      // 监听质权人类型改变
      creditorTypeChange(value){
        if(value == 'ENT'){
          if(this.pledgeInfo.creditor || this.pledgeInfo.acctBankNo){
            this.pledgeInfo.creditor = ''
            this.pledgeInfo.acctBankNo = ''
          }
        }else if(value == 'ORG'){
          if(this.pledgeInfo.creditor || this.pledgeInfo.acctBankNo || this.pledgeInfo.pledgeAmt){
            this.pledgeInfo.creditor = ''
            this.pledgeInfo.acctBankNo = ''
            this.pledgeInfo.pledgeAmt = ''
          }
        }
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'pledgeApplyList'})
      },
      // 下一步
      handleSubmit(){
        this.$refs.pledgeOneRef.validate(async valid => {
          if(!valid) return
          if(this.pledgeInfo.pledgeAmt == '0') return this.$message.error('质押金额要大于0')
          let params = {
            ticketId: this.ticketId,
            creditorType: this.pledgeInfo.creditorType,
            creditor: this.pledgeInfo.creditor,
            acctNo: this.pledgeInfo.acctNo,
            acctBankName: this.pledgeInfo.acctBankName,
            acctBankNo: this.pledgeInfo.acctBankNo,
            pledgeAmt: this.pledgeInfo.pledgeAmt,
            applyDate: this.pledgeInfo.applyDate,
            remark: this.pledgeInfo.remark
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/zy/mange/apply/save', this.$qs.stringify(params))
          if(res.code !== 200) return this.$message.error(res.msg)
          this.$emit('handleSubmit', {activeStep: 2})
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .pledge-one-form{
    width: 60%;
    .el-select{
      width: 100% !important;
    }
  }
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
</style>